.datetimeContainer {
    display: flex;
    position: relative;
}

.datePickerContainer[data-enable-time="true"] {
    padding-right: 0.25rem;
    width: 50%;
}

.datePickerContainer[data-enable-time="false"] {
    width: 100%;
}

.timePickerContainer[data-enable-date="true"] {
    padding-left: 0.25rem;
    width: 50%;
}

.timePickerContainer[data-enable-date="false"] {
    width: 100%;
}

.todayButton {
    border: 1px solid #757575 !important;
    box-shadow: none !important;
    color: #757575 !important;
    font-size: .5rem !important;
    font-weight: 600 !important;
    min-width: 0 !important;
    padding: 1px 12px !important;
    position: absolute !important;
    right: 24px;
    text-transform: capitalize !important;
    top: 18px;
}

.timePopover {
    padding: 0.25rem 0;
}

.timeMenuList {
    max-height: 10.5rem;
    overflow-y: auto;
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
}

.timeMenuItem:hover {
    opacity: 100%;
}

.timeMenuItem {
    opacity: 38%;
}

.selectedTimeItem {
    color: white !important;
}

.label {
    font-size: 0.8rem;
}

.labelRichText {
    color: rgb(0 0 0 / 60%);
    display: block;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: .75rem;
    letter-spacing: 0.00938rem;
    line-height: 1.4375rem;
}

.searchResultLabel {
    font-size: 0.8125rem;
}

.searchResultSublabel {
    font-size: 0.8rem;
}

.searchResultDescription {
    font-size: 0.75rem;
}

.searchResultContainer:hover {
    background-color: rgb(0 0 0 / 10%);
}

.newRecordButton {
    /* margin-bottom: -0.25rem !important; */
    /* margin-top: 0.25rem !important; */
    margin-left: -.5rem;
    width: 100%;
}

.newRecordButton[data-no-result="true"] {
    margin-left: -.5rem !important;
}

.newRecordButton>button {
    padding: 0 1rem 0.25 0.35rem !important;
    text-transform: none !important;
}

.newRecordButton>button>span {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: -2px !important;
}

.lookupNewRecord {
    background-color: rgb(244 246 254);
    height: 100%;
    left: 0;
    overflow-y: auto;
    position: fixed;
    top: 48px;
    width: 100%;
    z-index: 1001;
}

.isOutlookLookup {
    top: 0;
}

.lookupNewRecord>div {
    padding: 0 0.5rem;
}

.lookupNewRecord div[data-is-compact] {
    padding: 0.25rem;
}

.characterLimitIndicator {
    color: rgb(0 0 0 / 60%);
    font-size: 12px;
    font-style: italic;
    position: absolute;
    right: 0;
    top: 0;
}


.customEditor {
    overflow: scroll;
}

