/* Template Styles */

.introScreen {
  display: grid;
  gap: 0.5rem;
  padding: 0.5rem 0;
}

.introScreenCreateRecord {
  background-color: #fff;
  inset: 0;
  padding: 3rem 0.5rem 0.5rem;
  position: absolute;
  z-index: 10;
}

.inOffice {
  padding-top: 0.5rem;
}

.createRecord {
  background-color: #fff;
  border: 1px solid rgb(0 0 0 / 20%);
  margin-top: 0.5rem;
  padding: 0 1rem;
}

.logScreenTitle {
  color: #fff;
  display: flex;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.9375rem;
  font-weight: 500;
  justify-content: center;
  line-height: 1.5;
  margin: 0 -8px;
  padding: 0.5rem;
  z-index: 10001;
}

.logScreenTitleDebug {
  color: #fff;
  display: flex;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.9375rem;
  font-weight: 500;
  justify-content: center;
  line-height: 1.5;
  margin: 0 -8px;
  padding: 0.5rem;
  position: relative;
}

.investorflow {
  background-color: #fff;
  display: grid;
  margin: 0.5rem 0;
  padding: 1rem 1rem 2rem;
  place-items: center;
}

.investorflow>div {
  align-items: center;
}

.headline {
  margin-block-end: 0;
}

.closeSignInButton {
  background-color: #fff !important;
  margin-top: 0.5rem !important;
  width: 100%;
}

.buttonPadding {
  height: 4.5rem;
  width: 100%;
}

.btnImg {
  max-width: 205px;
  width: 100%;
}

.btnWrapper {
  margin: 8px 0 0 !important;
  padding: 0 !important;
}

.loginWrapper {
  background-color: #fff;
  margin-bottom: 0.5rem;
}

.selectWrapper {
  flex-direction: row !important;
}

.selectItem {
  width: 100%;
}

.outlookNav {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.debugAvatar {
  position: absolute;
  right: 0.5rem;
  top: 7.25px;
}

.outlookNavNoEmail {
  position: absolute;
  right: 8px;
  top: 14.5px;
}

.testStringify {
  font-size: .5rem;
  padding-top: 50rem;
  line-break: anywhere;
}

.deviceFlowTemplate {

  .deviceFlowWrapper {
    border: 1px solid #D1D1D6;
    padding: .75rem;
  }

  .boxHeading {
    border-bottom: 1px solid #D1D1D6;
    margin-left: -.85rem;
    width: calc(100% + 1.5rem);

    h1 {
      color: #37474f;
      font-size: .75rem;
      font-weight: 700;
      margin-bottom: .5rem;
      padding: 0 .75rem .25rem;
    }
  }

  .deviceFlowText {
    margin-top: 1rem;
  }

  span {
    color: #55575C;
    font-size: .85rem;
    font-weight: 300;
  }

  a {
    font-weight: 300;
    margin-bottom: 1.5rem;
  }

  .code {
    color: #FFFFFF;
    width: 100%;

    strong {
      border: 1px solid #D1D1D6;
      border-radius: 5px;
      color: #55575C;
      display: block;
      font-size: 1.5rem;
      font-weight: 300;
      letter-spacing: .5px;
      padding: .5rem;
      text-align: center;
      width: calc(100% - 1rem - 2px);
    }
  }

}

.cancelBtn {
  margin-top: 1rem !important;
  width: 100%;
}

.debugPannelTemplate {
  th[data-level="info"] {
    background-color: #aaeaff;
  }
  th[data-level="warn"] {
    background-color: #feffaa;
  }
  th[data-level="error"] {
    background-color: #eea897;
  }
}

.MSBanner {
  width: 100%;
}