/* Related Lists Styles */

:root {
    --themed-primary: #1976d2;
}

.relatedList {
    padding-bottom: 0.5rem;
}

.editButtonContainer {
    position: absolute;
    right: -10px;
    top: -4px;
    width: 2rem;
}

.checkboxContainer {
    position: absolute;
    right: 0;
    width: 26px;
}

.checkboxContainer>span {
    height: 18px;
    width: 18px;
}

.unmatchedWarning {
    position: absolute;
    right: 0;
    top: 20px;
    width: 26px;
}

.unmatchedWarning>svg {
    fill: #ef6c00 !important;
    height: 18px;
    width: 18px;
}

.unmatchedToolTip {
    background-color: #ef6c00 !important;
    font-size: 0.875rem !important;
    max-width: 15rem !important;
    padding: 0.5rem !important;
}

.aiContainer {
    padding-top: 3px;
}

.aiToolTip {
    background-color: #689f38 !important;
    font-size: 0.875rem !important;
    max-width: 14rem !important;
    padding: 0.5rem !important;
}

.investorFlowIcon svg {
    height: 12px !important;
    width: 12px !important;
}

.createButtonContainer {
    position: absolute;
    right: 0;
    width: 26px;
}

.createButtonContainer>button {
    height: 18px;
    width: 18px;
}

.createButtonContainer>button>svg {
    height: 18px !important;
    width: 18px !important;
}

.selectableEditBtnContainer {
    padding: 1px 8px;
}

.selectableEditBtnContainer>button {
    height: 1rem;
    width: 1rem;
}

.selectableEditBtnContainer>button>svg {
    height: 1rem;
    width: 1rem;
}

.titleValue {
    font-size: 0.8125rem;
    line-height: 1rem;
}

.description {
    line-height: 1rem;
    width: 100%;
    word-wrap: break-word;
}

a.description:focus-within {
    color: #ffa500 !important;
    box-shadow: 0 0 3pt 2pt #1976d2;
    transition: 250ms ease-in-out;

}

.description p {
    margin: 0.25rem 0;
}

.relatedList a:link {
    color: var(--themed-primary, #1976d2);
}

.relatedList a:visited {
    filter: brightness(50%);
}

.newRecordContainer {
    background-color: rgb(244 246 254);
    left: 0;
    min-height: calc(100% - 48px);
    position: absolute;
    top: 48px;
    width: 100%;
    z-index: 20;
}

.topZero {
    min-height: 100%;
    top: 0 !important;
}

.newRecordContainer>div {
    padding: 0 0.5rem;
}

.spacer {
    /* background-color: #ffffff; */
    padding-top: .75rem;
    /* position: relative;
    z-index: 1; */
}